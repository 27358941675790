import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["details-table-item", {
      'details-table-item--mobile-align-center': _ctx.mobileAlignCenter,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["details-table-item__left", {
        'align-middle': _ctx.labelAlignCenter,
        [`details-table-item__left--${_ctx.labelSize}`]: _ctx.labelSize,
      }])
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createElementVNode("span", {
          class: _normalizeClass({
            'opacity-half': _ctx.disabled
          })
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.labelTooltip)
            ? (_openBlock(), _createBlock(_component_tm_tooltip, {
                key: 0,
                position: "bottom",
                "max-width": "218px",
                class: "text-center"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.labelTooltip), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2)
      ], true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["details-table-item__right", [{
        'opacity-half no-pointer-events': _ctx.disabled,
        'align-middle': _ctx.cellAlignCenter,
        'reduce-padding': _ctx.reduceRightPadding,
      }, _ctx.rightClass]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.value), 1)
      ], true)
    ], 2)
  ], 2))
}