
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import kebabCase from 'lodash/kebabCase'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import type { DetailsTableItemType } from '@/definitions/shared/types'

export default defineComponent({
  components: { DetailsTableItem },
  props: {
    labelSize: {
      type: String as PropType<'' | 'large'>,
    },
    labelAlignCenter: {
      type: Boolean,
    },
    cellAlignCenter: {
      type: Boolean,
    },
    rightClass: {
      type: String,
    },
    items: {
      type: Array as PropType<DetailsTableItemType[]>,
    },
  },
  setup() {
    return {
      kebabCase,
    }
  },
})
