import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_mobile_activator = _resolveComponent("tm-auto-size-panel-mobile-activator")!
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel, { "min-hidden-items": 2 }, {
    "dropdown-activator": _withCtx(({ visibleItemsCount }) => [
      _createVNode(_component_tm_auto_size_panel_mobile_activator, { "visible-items-count": visibleItemsCount }, null, 8, ["visible-items-count"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "add",
        text: "Create a new rule",
        to: { name: 'base.services.automationRules.newRule' }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "edit",
        text: "Edit"
      }),
      (_ctx.rule.status.name === 'active')
        ? (_openBlock(), _createBlock(_component_tm_auto_size_panel_item_button, {
            key: 0,
            icon: "pause",
            text: "Pause"
          }))
        : (_openBlock(), _createBlock(_component_tm_auto_size_panel_item_button, {
            key: 1,
            icon: "play_arrow",
            text: "Resume"
          })),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "delete",
        text: "Delete",
        onClick: _ctx.openAutomationRuleDeleteModal
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}