
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import type { FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmCountry,
  },
  props: {
    countries: {
      type: Array as PropType<FlagCountry[]>,
      required: true,
    },
  },
})
