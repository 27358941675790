
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import ServicesAutomationRulesDetailsButtons from '@/components/pages/services/automationRules/ServicesAutomationRulesDetailsButtons.vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import TmCountriesRow from '@/components/shared/TmCountriesRow.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { AutomationRule } from '@/definitions/services/automationRules/types'

export default defineComponent({
  components: {
    TmBadges,
    TmBadge,
    TmCountriesRow,
    DetailsTable,
    DetailsTableItem,
    ServicesAutomationRulesDetailsButtons,
    PageBlock,
  },
  setup() {
    const route = useRoute()

    const rule = computed(() => (getTableData('automationRules') as AutomationRule[])
      .find(el => el.id === route.params.id))

    const breadcrumbsLink = computed<BreadcrumbsLink[]>(() => (rule.value)
      ? [
          {
            label: 'Automation rules',
            name: 'base.services.automationRules.rules',
          },
          {
            label: rule.value.name,
          },
        ]
      : [])

    return {
      rule,
      breadcrumbsLink,
    }
  },
})
