
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    labelSize: {
      type: String as PropType<'' | 'large' | 'xLarge'>,
    },
    mobileAlignCenter: {
      type: Boolean,
    },
    labelAlignCenter: {
      type: Boolean,
    },
    cellAlignCenter: {
      type: Boolean,
    },
    labelTooltip: {
      type: String,
      default: '',
    },
    rightClass: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    reduceRightPadding: {
      type: Boolean,
    },
  },
})
