
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'
import type { AutomationRule } from '@/definitions/services/automationRules/types'

export default defineComponent({
  components: {
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
  },
  props: {
    rule: {
      type: Object as PropType<AutomationRule>,
      required: true,
    },
  },
  setup() {
    const { openAutomationRuleDeleteModal } = useAutomationRuleOpenModal('rule')

    return {
      openAutomationRuleDeleteModal,
    }
  },
})
