import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-start items-center" }
const _hoisted_2 = {
  key: 0,
  class: "pr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        _createVNode(_component_tm_country, {
          country: country,
          class: "col-shrink"
        }, null, 8, ["country"]),
        (i < _ctx.countries.length - 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, ","))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}