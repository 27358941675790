import { computed } from 'vue'
import { useModals } from '@/compositions/modals'
import type { AutomationVariant } from '@/definitions/services/automationRules/types'

type UseAutomationRuleOpenModal = (type: AutomationVariant) => {
  openAutomationRuleDeleteModal: () => void
  openAutomationRuleAddKeyword: () => void

}

const useAutomationRuleOpenModal: UseAutomationRuleOpenModal = (type) => {
  const { openModal } = useModals()

  const title = computed(() => `Delete ${type}`)

  const openAutomationRuleDeleteModal = () => {
    openModal('confirmation', {
      title,
      text: `Are you sure you would like to delete this ${(type === 'log' ? 'entry' : type)}?\n This action cannot be undone.`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openAutomationRuleAddKeyword = () => {
    openModal('singleInput', {
      placeholder: 'Enter keyword name',
      message: 'Enter the keyword that you would like to add.',
      label: 'Enter your keyword',
      btnText: 'Add keyword',
      modalTitle: 'Add a new keyword',
    })
  }

  return {
    openAutomationRuleDeleteModal,
    openAutomationRuleAddKeyword,
  }
}

export default useAutomationRuleOpenModal
